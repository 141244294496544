body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
}
.uppy-DragDrop--is-dragdrop-supported {
    border: 2px dashed !important;
    border-color: #adadad !important;
}
.uppy-DragDrop-inner {
    padding: 20px 20px !important;
    width: 100;
}
.uppy-StatusBar:not(.is-waiting) {
    margin-top: 10px;
}
.uppy-Root {
    margin-top: 15px !important;
}
.error {
    padding-top: 10px;
    padding-bottom: 10px;
	color: red;
}
.uploaded-image {
    border-radius: 5px;
    margin-right: 5px;
    cursor: pointer;
}